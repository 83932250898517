"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    formatDateRange: function() {
        return formatDateRange;
    },
    getDate: function() {
        return getDate;
    }
});
const _datefns = require("date-fns");
function getDate(date) {
    if (!date) {
        return undefined;
    }
    if (typeof date === "object") {
        if (date instanceof Date) {
            return date;
        }
        return date.toDate();
    }
    return new Date(date);
}
const formatDateRange = (startDate, endDate)=>{
    let dateRange = "";
    if ((0, _datefns.format)(startDate, "MMM") === (0, _datefns.format)(endDate, "MMM")) {
        dateRange = `${(0, _datefns.format)(startDate, "MMM d")} - ${(0, _datefns.format)(endDate, "d, y")}`;
    } else {
        dateRange = `${(0, _datefns.format)(startDate, "MMM d")} - ${(0, _datefns.format)(endDate, "MMM d, y")}`;
    }
    return dateRange;
};
