"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    buttonGroupStyle: function() {
        return buttonGroupStyle;
    },
    buttonIconStyle: function() {
        return buttonIconStyle;
    },
    buttonStyle: function() {
        return buttonStyle;
    },
    buttonTextStyle: function() {
        return buttonTextStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const buttonStyle = (0, _tva.tva)({
    base: "group/button " + "rounded " + "flex-row " + "items-center " + "justify-center " + "data-[focus-visible=true]:web:outline-none " + "data-[focus-visible=true]:web:ring-2 " + "gap-2",
    variants: {
        // nonsense prop which was proposed to be added by designer
        no110MinWidth: {
            false: "min-w-[100px]"
        },
        action: {
            primary: "bg-primary-500 data-[hover=true]:bg-primary-600 data-[active=true]:bg-primary-700 border-primary-300 data-[hover=true]:border-primary-400 data-[active=true]:border-primary-500 data-[focus-visible=true]:web:ring-indicator-info",
            secondary: "bg-secondary-500 border-secondary-300 data-[hover=true]:bg-secondary-600 data-[hover=true]:border-secondary-400 data-[active=true]:bg-secondary-700 data-[active=true]:border-secondary-700 data-[focus-visible=true]:web:ring-indicator-info",
            positive: "bg-success-500 border-success-300 data-[hover=true]:bg-success-600 data-[hover=true]:border-success-400 data-[active=true]:bg-success-700 data-[active=true]:border-success-500 data-[focus-visible=true]:web:ring-indicator-info",
            negative: "bg-error-500 border-error-300 data-[hover=true]:bg-error-600 data-[hover=true]:border-error-400 data-[active=true]:bg-error-700 data-[active=true]:border-error-500 data-[focus-visible=true]:web:ring-indicator-info",
            default: "bg-transparent data-[hover=true]:bg-background-50 data-[active=true]:bg-blueGray-200",
            stripe: "bg-[#625BF6] data-[hover=true]:bg-[#625BF6] data-[active=true]:bg-[#625BF6]"
        },
        size: {
            sm: "",
            md: "",
            lg: ""
        },
        variant: {
            ghost: "bg-transparent border border-solid border-transparent",
            outline: "bg-transparent border data-[hover=true]:bg-blueGray-100 data-[active=true]:bg-transparent",
            solid: "",
            icon: "p-1 border border-transparent bg-transparent min-w-fit",
            link: "px-0 border-transparent bg-transparent"
        }
    },
    compoundVariants: [
        {
            variant: [
                "solid",
                "outline"
            ],
            size: "sm",
            class: "p-2"
        },
        {
            variant: [
                "solid",
                "outline"
            ],
            size: "md",
            class: "p-2"
        },
        {
            variant: [
                "solid",
                "outline"
            ],
            size: "lg",
            class: "px-4 py-2"
        },
        {
            variant: "ghost",
            size: "sm",
            class: "p-0.5"
        },
        {
            variant: "ghost",
            size: "md",
            class: "p-0.5"
        },
        {
            variant: "ghost",
            size: "lg",
            class: "px-1 py-0.5"
        },
        {
            action: "primary",
            variant: "solid",
            class: "bg-primary-600 " + "data-[hover=true]:bg-primary-700 " + "data-[active=true]:bg-primary-600 " + "data-[active=true]:web:ring-2 " + "data-[active=true]:web:ring-blue-600/[.5] " + "data-[focus-visible=true]:web:ring-indicator-info " + "data-[disabled=true]:bg-blueGray-200"
        },
        {
            action: "primary",
            variant: "ghost",
            class: "data-[hover=true]:bg-primary-100 " + "data-[hover=true]:border-primary-200 " + "data-[active=true]:bg-primary-100 " + "data-[active=true]:border-primary-300 " + "data-[focus-visible=true]:web:ring-indicator-info"
        },
        {
            action: "primary",
            variant: "outline",
            class: "border-blueGray-200 " + "data-[hover=true]:bg-blueGray-100 " + "data-[hover=true]:border-blueGray-300 " + "data-[active=true]:bg-blueGray-100 " + "data-[active=true]:border-blueGray-300 " + "data-[focus-visible=true]:web:ring-indicator-info " + "data-[disabled=true]:bg-blueGray-200 " + "data-[disabled=true]:border-blueGray-200"
        },
        {
            action: "negative",
            variant: "ghost",
            class: "data-[hover=true]:bg-error-100 " + "data-[hover=true]:border-error-200 " + "data-[active=true]:bg-error-100 " + "data-[active=true]:border-error-300 " + "data-[focus-visible=true]:web:ring-indicator-info"
        },
        {
            action: "primary",
            variant: "icon",
            class: "data-[hover=true]:bg-blueGray-100 " + "data-[hover=true]:border-blueGray-200 " + "data-[active=true]:bg-blueGray-100 " + "data-[active=true]:border-blueGray-300 " + "data-[focus-visible=true]:web:ring-indicator-info " + "data-[disabled=true]:bg-blueGray-200 " + "data-[disabled=true]:border-blueGray-200"
        }
    ]
});
const buttonTextStyle = (0, _tva.tva)({
    base: "font-body font-medium leading-lg web:select-none",
    parentVariants: {
        action: {
            primary: "",
            secondary: "",
            negative: ""
        },
        size: {
            sm: "text-xs leading-[15px]",
            md: "text-sm leading-[18px]",
            lg: "text-md leading-[20px]"
        },
        variant: {
            ghost: "",
            outline: "",
            solid: "",
            icon: "",
            link: "data-[hover=true]:underline data-[active=true]:underline"
        }
    },
    parentCompoundVariants: [
        {
            action: "primary",
            variant: "solid",
            class: "text-white data-[disabled=true]:text-blueGray-400"
        },
        {
            action: "default",
            variant: "ghost",
            class: "text-blueGray-600 " + "data-[hover=true]:text-blueGray-800 " + "data-[active=true]:text-blueGray-800 " + "data-[disabled=true]:text-blueGray-200"
        },
        {
            action: "primary",
            variant: "ghost",
            class: "text-primary-600 " + "data-[hover=true]:text-primary-800 " + "data-[active=true]:text-primary-800 " + "data-[disabled=true]:text-blueGray-200"
        },
        {
            action: "negative",
            variant: "ghost",
            class: "text-error-600 " + "data-[hover=true]:text-error-800 " + "data-[active=true]:text-error-800 " + "data-[disabled=true]:text-error-200"
        },
        {
            action: "secondary",
            variant: "outline",
            class: "text-blueGray-600 " + "data-[hover=true]:text-blueGray-800 " + "data-[active=true]:text-blueGray-800 " + "data-[disabled=true]:text-blueGray-400"
        },
        {
            action: "primary",
            variant: "link",
            class: "text-primary-600 " + "data-[hover=true]:text-primary-600 " + "data-[active=true]:text-primary-600 " + "data-[disabled=true]:text-primary-600/50"
        },
        {
            action: "stripe",
            variant: "solid",
            class: "text-white"
        }
    ]
});
const buttonIconStyle = (0, _tva.tva)({
    base: "",
    parentVariants: {
        size: {
            sm: "h-3 w-3",
            md: "h-4 w-4",
            lg: "h-5 w-5"
        },
        action: {
            primary: "",
            secondary: "",
            negative: ""
        },
        variant: {
            ghost: "",
            outline: "",
            solid: "",
            icon: "fill-blueGray-600",
            link: "data-[hover=true]:underline data-[active=true]:underline"
        }
    },
    parentCompoundVariants: [
        {
            action: "primary",
            variant: "solid",
            class: "fill-white data-[disabled=true]:fill-blueGray-400"
        },
        {
            action: "primary",
            variant: "ghost",
            class: "fill-primary-600 " + "data-[hover=true]:fill-primary-800 " + "data-[active=true]:fill-primary-800 " + "data-[disabled=true]:fill-blueGray-200"
        },
        {
            action: "primary",
            variant: "outline",
            class: "fill-blueGray-600 " + "data-[hover=true]:fill-blueGray-800 " + "data-[active=true]:fill-blueGray-800 " + "data-[disabled=true]:fill-blueGray-200"
        },
        {
            action: "negative",
            variant: "ghost",
            class: "fill-error-600 " + "data-[hover=true]:fill-error-800 " + "data-[active=true]:fill-error-800 " + "data-[disabled=true]:fill-error-200"
        },
        {
            action: "default",
            variant: "ghost",
            class: "fill-blueGray-600 " + "data-[hover=true]:fill-blueGray-800 " + "data-[active=true]:fill-blueGray-800 " + "data-[disabled=true]:fill-blueGray-200"
        },
        {
            action: "secondary",
            variant: "outline",
            class: "fill-blueGray-600 " + "data-[hover=true]:fill-blueGray-800 " + "data-[active=true]:fill-blueGray-800 " + "data-[disabled=true]:fill-blueGray-400"
        }
    ]
});
const buttonGroupStyle = (0, _tva.tva)({
    base: "",
    variants: {
        space: {
            xs: "gap-1",
            sm: "gap-2",
            md: "gap-3",
            lg: "gap-4",
            xl: "gap-5",
            "2xl": "gap-6",
            "3xl": "gap-7",
            "4xl": "gap-8"
        },
        isAttached: {
            true: "gap-0"
        }
    }
});
